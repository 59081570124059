<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        For each of the molecular geometries listed below, determine which approximate bond angles
        would be present. Check all that apply.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">
              <stemble-latex content="$\textbf{Geometry}$" />
            </th>
            <th v-for="angleOption in angleOptions" :key="angleOption" style="font-size: 15px">
              <stemble-latex :content="`$${angleOption}^\\circ$`" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(geometry, i) in geometries" :key="geometry">
            <td>
              <stemble-latex :content="`$\\text{${geometry}}$`" />
            </td>
            <td v-for="angleOption in angleOptions" :key="angleOption" style="text-align: center">
              <v-checkbox v-model="inputs[inputNames[i]]" :value="angleOption" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question243a',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        squarePlanar: [],
        tetrahedral: [],
        linear: [],
        geom4: [],
      },
    };
  },
  computed: {
    angleOptions() {
      return ['90', '109', '120', '180'];
    },
    geometries() {
      return ['Square planar', 'Tetrahedral', 'Linear', this.geometry4];
    },
    inputNames() {
      return ['squarePlanar', 'tetrahedral', 'linear', 'geom4'];
    },
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    geometry4() {
      if (this.versionVariable.value === 1) {
        return 'Octahedral';
      } else if (this.versionVariable.value === 2) {
        return 'Square Pyramidal';
      } else if (this.versionVariable.value === 3) {
        return 'Trigonal Bipyramidal';
      } else if (this.versionVariable.value === 4) {
        return 'Seesaw';
      } else if (this.versionVariable.value === 5) {
        return 'T-shape';
      } else {
        return '';
      }
    },
  },
};
</script>
